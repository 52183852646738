<template>
  <div class="LoversCustom">
    <div class="loversBox">
      <!-- 参数信息 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">参数信息</div>
        <div class="loversBox_title_two noMaleRing">
          女戒
        </div>
        <div class="loversBox_title_three noMaleRing">
          男戒
        </div>
      </div>
      <!-- 金重大小 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">{{ mosaic ? '主石重大小：' : '金重大小：' }}</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox textureAndMainSton">
            <el-select @change="getPriceRange('女', '主石')" v-model="femaleDataFrom.mainStone" :popper-append-to-body="false" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in femaleDropDownOptional.mainStoneList" :key="index" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox textureAndMainSton">
            <el-select @change="getPriceRange('男', '主石')" v-model="maleDataFrom.mainStone" placeholder :popper-append-to-body="false" clearable>
              <div>
                <el-option v-for="(item, index) in maleDropDownOptional.mainStoneList" :key="index" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 可选材质 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">可选材质</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox textureAndMainSton">
            <el-select v-model="femaleDataFrom.texture" @change="getPriceRange('女')" :popper-append-to-body="false" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in femaleDropDownOptional.textureList" :key="index" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox  textureAndMainSton">
            <el-select @change="getPriceRange('男')" v-model="maleDataFrom.texture" :popper-append-to-body="false" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in maleDropDownOptional.textureList" :key="index" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 主石重量 -->
      <div class="loversBox_title" v-if="mosaic">
        <div class="loversBox_title_one">主石重量</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox">
            <el-input
              @blur="onBlur('女', femaleDataFrom.mainStone, femaleDataFrom.mainStoneSize)"
              onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,5})?).*$/g, '$1')"
              v-model="femaleDataFrom.mainStoneSize"
              clearable
              placeholder="请输入主石重量"
            ></el-input>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox">
            <el-input
              @blur="onBlur('男', maleDataFrom.mainStone, maleDataFrom.mainStoneSize)"
              onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,5})?).*$/g, '$1')"
              v-model="maleDataFrom.mainStoneSize"
              clearable
              placeholder="请输入主石重量"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- 净度 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">净<span class="hidden">间隔</span>度</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('女')" :popper-append-to-body="false" v-model="femaleDataFrom.mainStoneClarity" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in femaleDropDownOptional.mainStoneClarity" :key="index" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('男')" :popper-append-to-body="false" v-model="maleDataFrom.mainStoneClarity" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in maleDropDownOptional.mainStoneClarity" :key="index" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 色度 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">色<span class="hidden">间隔</span>度</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('女')" :popper-append-to-body="false" v-model="femaleDataFrom.mainStoneColour" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in femaleDropDownOptional.mainStoneColour" :key="index" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('男')" :popper-append-to-body="false" v-model="maleDataFrom.mainStoneColour" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in maleDropDownOptional.mainStoneColour" :key="index" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 辅石名称 -->
      <div class="loversBox_title" v-if="femaleDropDownOptional.gemNameList && femaleDropDownOptional.gemNameList.length > 0">
        <div class="loversBox_title_one">
          辅石名称
        </div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('女')" :popper-append-to-body="false" v-model="femaleDataFrom.gemName" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in femaleDropDownOptional.gemNameList" :key="index" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('男')" :popper-append-to-body="false" v-model="maleDataFrom.gemName" placeholder clearable>
              <div>
                <el-option v-for="(item, index) in maleDropDownOptional.gemNameList" :key="index" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 版型 -->
      <div class="loversBox_title" v-if="femaleDropDownOptional.formatTypes && femaleDropDownOptional.formatTypes.length > 0">
        <div class="loversBox_title_one">版<span class="hidden">间隔</span>型</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('女')" :popper-append-to-body="false" v-model="femaleDataFrom.formatType" placeholder clearable>
              <div>
                <el-option v-for="item in femaleDropDownOptional.formatTypes" :key="item" :label="item.label" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('男')" :popper-append-to-body="false" v-model="maleDataFrom.formatType" placeholder clearable>
              <div>
                <el-option v-for="item in maleDropDownOptional.formatTypes" :key="item" :label="item.label" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 规格 -->
      <div class="loversBox_title" v-if="productCategoryList.includes(DataList.productCategoryName)">
        <div class="loversBox_title_one">手<span class="hidden">间隔</span>寸</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('女')" :popper-append-to-body="false" v-model="femaleDataFrom.ringSize" placeholder clearable>
              <div>
                <el-option v-for="item in femaleDropDownOptional.handSizeList" :key="item" :label="item.label" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox commonlyUsed">
            <el-select @change="getPriceRange('男')" :popper-append-to-body="false" v-model="maleDataFrom.ringSize" placeholder clearable>
              <div>
                <el-option v-for="item in maleDropDownOptional.handSizeList" :key="item" :label="item.label" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 戒壁封石 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">
          戒壁封石
        </div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox">
            <el-radio-group v-model="femaleDataFrom.seal">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox">
            <el-radio-group v-model="maleDataFrom.seal">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 是否改款 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">
          是否改款
        </div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox">
            <el-radio-group v-model="femaleDataFrom.shapeOptimize">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox">
            <el-radio-group v-model="maleDataFrom.shapeOptimize">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 证书类型 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">G<span style="margin:0 20px">I</span>A</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox">
            <el-radio-group v-model="femaleDataFrom.gia" @change="certificateTypeMethod('女')">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox">
            <el-radio-group v-model="maleDataFrom.gia" @change="certificateTypeMethod('女')">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 切工 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">切<span class="hidden">间隔</span>工</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox">
            <div class="fluorescenceCl" v-if="femaleDataFrom.gia === 'Y'">
              <el-radio-group v-model="femaleDataFrom.cut">
                <el-radio-button label="VG（很好）"></el-radio-button>
                <el-radio-button label="EX（极好）"></el-radio-button>
                <el-radio-button label="G（好）"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox">
            <div class="fluorescenceCl" v-if="maleDataFrom.gia === 'Y'">
              <el-radio-group v-model="maleDataFrom.cut">
                <el-radio-button label="VG（很好）"></el-radio-button>
                <el-radio-button label="EX（极好）"></el-radio-button>
                <el-radio-button label="G（好）"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
      <!-- 荧光 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">荧<span class="hidden">间隔</span>光</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox">
            <div class="fluorescenceCl" v-if="femaleDataFrom.gia === 'Y'">
              <el-radio-group v-model="femaleDataFrom.fluorescence">
                <el-radio-button label="N（无）"></el-radio-button>
                <el-radio-button label="F（弱）"></el-radio-button>
                <el-radio-button label="M（中）"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox">
            <div class="fluorescenceCl" v-if="maleDataFrom.gia === 'Y'">
              <el-radio-group v-model="maleDataFrom.fluorescence">
                <el-radio-button label="N（无）"></el-radio-button>
                <el-radio-button label="F（弱）"></el-radio-button>
                <el-radio-button label="M（中）"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
      <!-- 下单件数 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">下单件数</div>
        <div class="loversBox_title_two numberFx">
          <el-input-number
            @change="realTimePriceOrderNumber('女', $event)"
            @input.native="updataQuantity('女', $event)"
            v-model="femaleDataFrom.quantity"
            :min="0"
            :max="9999"
            label="描述文字"
          ></el-input-number>
          <span style="font-size:14px">(起订量{{ leastQuantity }}件，可定单不同规格)</span>
        </div>
        <div class="loversBox_title_three numberFx">
          <el-input-number
            @change="realTimePriceOrderNumber('男', $event)"
            @input.native="updataQuantity('男', $event)"
            v-model="maleDataFrom.quantity"
            :min="0"
            :max="9999"
            label="描述文字"
          ></el-input-number>
          <span style="font-size:14px">(起订量{{ leastQuantity }}件，可定单不同规格)</span>
        </div>
      </div>
      <!-- 批发价格 -->
      <div class="loversBox_title" v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">
        <div class="loversBox_title_one">批发价格</div>
        <div class="loversBox_title_two">
          <span style="font-size:18px;color:#ec9368">{{ femaleDataFrom.tradePrice }}</span>
        </div>
        <div class="loversBox_title_three">
          <span style="font-size:18px;color:#ec9368">{{ maleDataFrom.tradePrice }}</span>
        </div>
      </div>
      <!-- 价格范围 -->
      <div class="loversBox_title">
        <div class="loversBox_title_one">标签价格</div>
        <div class="loversBox_title_two">
          <span style="font-size:18px;color:#ec9368">{{ maleDataFrom.labelPrice }}</span>
        </div>
        <div class="loversBox_title_three">
          <span style="font-size:18px;color:#ec9368">{{ maleDataFrom.labelPrice }}</span>
        </div>
      </div>
      <!-- 备注 -->
      <div class="loversBox_title" style="min-height:150px;">
        <div class="loversBox_title_one" style="display: flex;justify-content: center;align-items: center;">备<span class="hidden">间隔</span>注</div>
        <div class="loversBox_title_two" style="min-height:150px">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6 }"
            :maxlength="64"
            placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
            v-model="femaleDataFrom.productRemark"
          ></el-input>
        </div>
        <div class="loversBox_title_three">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6 }"
            :maxlength="64"
            placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
            v-model="maleDataFrom.productRemark"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PRODUCT_CATEGOYR_NAME } from '@/utils/const';
import { getOptional } from '@/api/detailsApi';
export default {
  name: 'LoversCustom',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    mosaic: {
      type: Boolean,
      default() {
        return false;
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      femaleDataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        quantity: 0, // 数量
        gia: 'N', // GIA
        fluorescence: 'M（中）', // 荧光
        cut: 'G（好）', // 切工
        shapeOptimize: 'N', // 是否改款
        seal: 'N', // 戒壁封石
        goldWeigh: '',
        gender: '女',
        labelPrice: 0,
        tradePrice: 0,
      },
      maleDataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        quantity: 0, // 数量
        isCouples: this.DataList.isCouples, // 是否情侣款
        gia: 'N', // GIA
        fluorescence: 'M（中）', // 荧光
        cut: 'G（好）', // 切工
        shapeOptimize: 'N', // 是否改款
        seal: 'N', // 戒壁封石
        goldWeigh: '',
        gender: '男',
        labelPrice: 0,
        tradePrice: 0,
      },
      femaleDropDownOptional: {},
      maleDropDownOptional: {},
      productCategoryList: PRODUCT_CATEGOYR_NAME,
    };
  },
  mounted() {
    // 获取可选属性
    this.getOptional();
    // 获取女金重
    this.getPriceRange('女');
    // 获取男金重
    this.getPriceRange('男');
  },
  methods: {
    //改变gia
    certificateTypeMethod(gender) {
      if (gender === '女') {
        this.femaleDataFrom.fluorescence = 'M（中）';
        this.femaleDataFrom.cut = 'G（好）';
      } else {
        this.maleDataFrom.fluorescence = 'M（中）';
        this.maleDataFrom.cut = 'G（好）';
      }
    },
    //获取价格范围
    getPriceRange(gender, text) {
      let obj = {};
      if (gender === '女') {
        obj = this.femaleDataFrom;
        if (text === '主石' && mosaic) {
          this.femaleDataFrom.mainStoneSize = this.femaleDataFrom.mainStone?.split('-')[0];
        }
      } else {
        obj = this.maleDataFrom;
        if (text === '主石' && mosaic) {
          this.maleDataFrom.mainStoneSize = this.maleDataFrom.mainStone?.split('-')[0];
        }
      }
      if (text === '主石' && gender === '女') {
        this.femaleDataFrom.texture = null;
        this.femaleDataFrom.mainStoneClarity = null;
        this.femaleDataFrom.mainStoneColour = null;
        this.femaleDataFrom.gemName = null;
        this.femaleDataFrom.formatType = null;
        this.femaleDataFrom.ringSize = null;
        this.getOptional(text);
      } else if (text === '主石' && gender === '男') {
        this.maleDataFrom.texture = null;
        this.maleDataFrom.mainStoneClarity = null;
        this.maleDataFrom.mainStoneColour = null;
        this.maleDataFrom.gemName = null;
        this.maleDataFrom.formatType = null;
        this.maleDataFrom.ringSize = null;
        this.getOptional(text);
      }
      // getPriceRange(obj).then(res => {
      //   if (gender === '女') {
      //     this.femaleDataFrom = { ...this.femaleDataFrom, ...res.data }
      //   } else {
      //     this.maleDataFrom = { ...this.maleDataFrom, ...res.data }
      //   }
      // })
    },
    // 数量改变实时触发
    updataQuantity(gender, event) {
      this.realTimePriceOrderNumber(gender, Number(event.target.value));
    },
    // 数量改变计算价格
    realTimePriceOrderNumber(gender, value) {
      this.$emit('selectionCustom', value, gender);
      // this.getPriceRange(gender)
    },
    // 获取可选属性
    getOptional(text) {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        columnCode: this.$route.query.codeType,
        mainStone: '',
        gender: '',
      };
      obj.gender = '女';
      obj.mainStone = this.femaleDataFrom.mainStone;
      getOptional(obj).then((res) => {
        this.femaleDropDownOptional = { ...res.data };
        this.femaleDataFrom.isRevision = this.femaleDropDownOptional.isRevision;
        // 规格重新排序
        this.femaleDropDownOptional.handSizeList?.sort(function(a, b) {
          return a - b;
        });
        if (text === '主石') {
          if (this.femaleDropDownOptional.textureList.length > 0) {
            this.femaleDataFrom.texture = this.femaleDropDownOptional.textureList[0];
          }
          if (this.femaleDropDownOptional.mainStoneClarity.length > 0) {
            this.femaleDataFrom.mainStoneClarity = this.femaleDropDownOptional.mainStoneClarity[0];
          }
          if (this.femaleDropDownOptional.mainStoneColour.length > 0) {
            this.femaleDataFrom.mainStoneColour = this.femaleDropDownOptional.mainStoneColour[0];
          }
          if (this.maleDropDownOptional.gemNameList.length > 0) {
            this.femaleDataFrom.gemName = this.femaleDropDownOptional.gemNameList[0];
          }
          if (this.femaleDropDownOptional.formatTypes.length > 0) {
            this.femaleDataFrom.formatType = this.femaleDropDownOptional.formatTypes[0];
          }
          if (this.femaleDropDownOptional.handSizeList.length > 0) {
            this.femaleDataFrom.ringSize = this.femaleDropDownOptional.handSizeList[0];
          }
        }
      });
      obj.gender = '男';
      obj.mainStone = this.maleDataFrom.mainStone;
      getOptional(obj).then((res) => {
        this.maleDropDownOptional = { ...res.data };
        this.maleDataFrom.isRevision = this.maleDropDownOptional.isRevision;
        // 规格重新排序
        this.maleDropDownOptional.handSizeList?.sort(function(a, b) {
          return a - b;
        });
        if (text === '主石') {
          if (this.maleDropDownOptional.textureList.length > 0) {
            this.maleDataFrom.texture = this.maleDropDownOptional.textureList[0];
          }
          if (this.maleDropDownOptional.mainStoneClarity.length > 0) {
            this.maleDataFrom.mainStoneClarity = this.maleDropDownOptional.mainStoneClarity[0];
          }
          if (this.maleDropDownOptional.mainStoneColour.length > 0) {
            this.maleDataFrom.mainStoneColour = this.maleDropDownOptional.mainStoneColour[0];
          }
          if (this.maleDropDownOptional.gemNameList.length > 0) {
            this.maleDataFrom.gemName = this.maleDropDownOptional.gemNameList[0];
          }
          if (this.maleDropDownOptional.formatTypes.length > 0) {
            this.maleDataFrom.formatType = this.maleDropDownOptional.formatTypes[0];
          }
          if (this.maleDropDownOptional.handSizeList.length > 0) {
            this.maleDataFrom.ringSize = this.maleDropDownOptional.handSizeList[0];
          }
        }
      });
    },
    //输入主石重量判断
    onBlur(gender, mainStone, mainStoneSize) {
      if (mainStone && mainStoneSize) {
        var arr = mainStone.split('-');
        if ((mainStoneSize && Number(mainStoneSize) < Number(arr[0])) || Number(mainStoneSize) > Number(arr[1])) {
          this.$message({
            message: '超过主石范围',
            type: 'warning',
            duration: 1500,
          });
          if (gender === '女') {
            this.femaleDataFrom.mainStoneSize = '';
          } else {
            this.maleDataFrom.mainStoneSize = '';
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.LoversCustom {
  .hidden {
    visibility: hidden;
  }

  .noMaleRing {
    background: #fff2ea;
    font-size: 18px;
    color: #000000;
  }

  .loversSelectBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }
  }

  .textureAndMainSton {
    text-align: left;

    .el-scrollbar__view li {
      width: 254px;
    }
  }

  .commonlyUsed {
    text-align: left;

    .el-scrollbar__view li {
      width: 122px;
    }
  }

  .el-select-dropdown__item {
    margin: 10px 0 0 10px;
    display: inline-block;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border: 1px solid #d3d3d3;
    padding: 0;
    font-size: 16px;
    color: #000;
  }

  .el-select-dropdown__item.selected {
    background: #fff2ea;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    border: 1px solid #ee9c75;
  }

  .fluorescenceCl {
    ::v-deep .el-radio-button__inner {
      width: 80px;
      margin-left: 20px;
      border-left: 1px solid #dcdfe6;
      padding: 10px 5px;
      margin: 5px;
    }

    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #fff;
      background-color: #ec9368;
      border-color: #ec9368;
      box-shadow: none;
    }

    ::v-deep .el-radio-button__inner:hover {
      color: rgb(236, 147, 104);
    }
  }
}
</style>
