<template>
  <div class="loverSortBox">
    <div class="spotTabs">
      <el-tabs v-model="spotActive" type="card">
        <el-tab-pane label="男款" name="first">
          <div class="singleSpot" v-if="maleTotal > 10 && femaleTotal > 10">
            <div class="infinite-list-wrapper" style="overflow:auto;font-size:14px;max-height:700px;">
              <ul class="list" v-infinite-scroll="maleLoad" infinite-scroll-disabled="maledisabled" infinite-scroll-distance="1">
                <li v-for="(item, index) in maleSpotList" class="list-item" :key="index">
                  <div class="listChild">
                    <div class="specifications">
                      <div>
                        材质：<span>{{ item.texture }}</span>
                      </div>
                      <div>
                        净度：<span>{{ item.mainStoneClarity }}</span>
                      </div>
                      <div>
                        色度：<span>{{ item.mainStoneColor }}</span>
                      </div>
                      <div>
                        规格：<span>{{ item.spec }}</span>
                      </div>
                      <div>
                        主石名称：<span>{{ item.mainStoneName }}</span>
                      </div>
                      <div>
                        主石数量：<span>{{ item.mainStoneNumber }}</span>
                      </div>
                      <div>
                        主石重量：<span>{{ item.mainStoneWeight }}</span>
                      </div>
                    </div>
                    <div class="specifications">
                      <div>
                        总重量：<span>{{ item.totalWeight }}</span>
                      </div>
                      <div>
                        辅石1名称：<span>{{ item.assistStoneNameOne }}</span>
                      </div>
                      <div>
                        辅石1数量：<span>{{ item.assistStoneNumberOne }}</span>
                      </div>
                      <div>
                        辅石1重量：<span>{{ item.assistStoneWeightOne }}</span>
                      </div>
                      <div>
                        辅石2名称：<span>{{ item.assistStoneNameTwo }}</span>
                      </div>
                      <div>
                        辅石2数量：<span>{{ item.assistStoneNumberTwo }}</span>
                      </div>
                      <div>
                        辅石2重量：<span>{{ item.assistStoneWeightTwo }}</span>
                      </div>
                    </div>
                    <div class="specifications">
                      <div>
                        GIA：<span>{{ item.gia | cutFilter }}</span>
                      </div>
                      <div>
                        系列：<span>{{ item.seriesName }}</span>
                      </div>
                      <div v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">
                        批发价格：<span class="colorClass">￥{{ item.tradePrice }}</span>
                      </div>
                      <div>
                        标签价格：<span class="colorClass">￥{{ item.labelPrice }}</span>
                      </div>
                      <div>
                        特价款：<span>{{ item.bargainPrice }}</span>
                      </div>
                      <div v-if="item.formatType">
                        版型：<span>{{ item.formatType }}</span>
                      </div>
                    </div>
                    <div class="wetherOrder">
                      <el-checkbox
                        :disabled="item.isCart !== 0"
                        v-model="item.switchFlag"
                        @change="switchChange(item, $event, '男')"
                        class="switchClass"
                      ></el-checkbox>
                    </div>
                  </div>
                </li>
              </ul>
              <p v-if="maleSpotLoading" class="lodClass">加载中...</p>
              <p v-if="maleMore" class="noMore">没有更多了</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="女款" name="second">
          <div class="singleSpot" v-if="maleTotal > 10 && femaleTotal > 10">
            <div class="infinite-list-wrapper" style="overflow:auto;font-size:14px;max-height:700px;">
              <ul class="list" v-infinite-scroll="femaleLoad" infinite-scroll-disabled="femaledisabled" infinite-scroll-distance="1">
                <li v-for="(item, index) in femaleSpotList" class="list-item" :key="index">
                  <div class="listChild">
                    <div class="specifications">
                      <div>
                        材质：<span>{{ item.texture }}</span>
                      </div>
                      <div>
                        净度：<span>{{ item.mainStoneClarity }}</span>
                      </div>
                      <div>
                        色度：<span>{{ item.mainStoneColor }}</span>
                      </div>
                      <div>
                        规格：<span>{{ item.spec }}</span>
                      </div>
                      <div>
                        主石名称：<span>{{ item.mainStoneName }}</span>
                      </div>
                      <div>
                        主石数量：<span>{{ item.mainStoneNumber }}</span>
                      </div>
                      <div>
                        主石重量：<span>{{ item.mainStoneWeight }}</span>
                      </div>
                    </div>
                    <div class="specifications">
                      <div>
                        总重量：<span>{{ item.totalWeight }}</span>
                      </div>
                      <div>
                        辅石1名称：<span>{{ item.assistStoneNameOne }}</span>
                      </div>
                      <div>
                        辅石1数量：<span>{{ item.assistStoneNumberOne }}</span>
                      </div>
                      <div>
                        辅石1重量：<span>{{ item.assistStoneWeightOne }}</span>
                      </div>
                      <div>
                        辅石2名称：<span>{{ item.assistStoneNameTwo }}</span>
                      </div>
                      <div>
                        辅石2数量：<span>{{ item.assistStoneNumberTwo }}</span>
                      </div>
                      <div>
                        辅石2重量：<span>{{ item.assistStoneWeightTwo }}</span>
                      </div>
                    </div>
                    <div class="specifications">
                      <div>
                        GIA：<span>{{ item.gia | cutFilter }}</span>
                      </div>
                      <div>
                        系列：<span>{{ item.seriesName }}</span>
                      </div>
                      <div v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">
                        批发价格：<span class="colorClass">￥{{ item.tradePrice }}</span>
                      </div>
                      <div>
                        标签价格：<span class="colorClass">￥{{ item.labelPrice }}</span>
                      </div>
                      <div>
                        特价款：<span>{{ item.bargainPrice }}</span>
                      </div>
                      <div v-if="item.formatType">
                        版型：<span>{{ item.formatType }}</span>
                      </div>
                    </div>
                    <div class="wetherOrder">
                      <el-checkbox
                        :disabled="item.isCart !== 0"
                        v-model="item.switchFlag"
                        @change="switchChange(item, $event, '女')"
                        class="switchClass"
                      ></el-checkbox>
                    </div>
                  </div>
                </li>
              </ul>
              <p v-if="femaleSpotLoading" class="lodClass">加载中...</p>
              <p v-if="femaleMore" class="noMore">没有更多了</p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="sortBoxChild">
      <div class="sortingFilter">
        <el-select v-model="sorting" @change="loverSpotOrder" :popper-append-to-body="false" placeholder="排序">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="inventory">
        库存&nbsp;{{
          spotActive == 'first' ? (maleTotal > 10 && femaleTotal > 10 ? maleTotal : 0) : maleTotal > 10 && femaleTotal > 10 ? femaleTotal : 0
        }}&nbsp;件
      </div>
    </div>
  </div>
</template>
<script>
import { cutFilter } from '@/utils/filetrs';
import { getDiamondSpotList } from '@/api/detailsApi';
export default {
  name: '',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      spotActive: 'first',
      sorting: '主石重量由低到高',
      options: [
        {
          value: '主石重量由低到高',
          label: '主石重量由低到高',
        },
        {
          value: '主石重量由高到低',
          label: '主石重量由高到低',
        },
        {
          value: '标签价格由低到高',
          label: '标签价格由低到高',
        },
        {
          value: '标签价格由高到低',
          label: '标签价格由高到低',
        },
      ],
      femaleSpotList: [],
      maleSpotList: [],
      femaleSpotLoading: false, //女款
      maleSpotLoading: false, //男款
      femaleFilterCoverShow: false,
      maleFilterCoverShow: false,
      maleTotal: 0,
      femaleTotal: 0,
      femalePageSize: 8,
      femalePageNum: 1,
      malePageSize: 8,
      malePageNum: 1,
      sortCode: '1',
      isAsc: true,
    };
  },
  filters: {
    cutFilter,
  },
  mounted() {
    this.getFemaleDiamondSpotList();
    this.getMaleDiamondSpotList();
  },
  computed: {
    femaleMore() {
      return this.femaleTotal <= this.femaleSpotList.length;
    },
    femaledisabled() {
      return this.femaleSpotLoading || this.femaleMore;
    },
    maleMore() {
      return this.maleTotal <= this.maleSpotList.length;
    },
    maledisabled() {
      return this.maleSpotLoading || this.maleMore;
    },
  },
  methods: {
    // 获取女款现货
    getFemaleDiamondSpotList() {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        gender: '女',
        size: this.femalePageSize,
        current: this.femalePageNum,
        sortCode: this.sortCode,
        columnCode: this.$route.query.codeType,
        isAsc: this.isAsc,
      };
      this.femaleFilterCoverShow = true;
      getDiamondSpotList(obj).then((res) => {
        res.data.records?.forEach((item) => {
          item.switchFlag = false;
          this.femaleSpotList.push(item);
        });
        if (res.data.total === 0) {
          this.$emit('toCustom');
        }
        this.femaleFilterCoverShow = false;
        this.femaleSpotLoading = false;
        this.femaleTotal = res.data.total;
      });
    },
    // 获取男款现货
    getMaleDiamondSpotList() {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        gender: '男',
        size: this.malePageSize,
        current: this.malePageNum,
        sortCode: this.sortCode,
        columnCode: this.$route.query.codeType,
        isAsc: this.isAsc,
      };
      this.maleFilterCoverShow = true;
      getDiamondSpotList(obj).then((res) => {
        res.data.records?.forEach((item) => {
          item.switchFlag = false;
          this.maleSpotList.push(item);
        });
        if (res.data.total === 0) {
          this.$emit('toCustom');
        }
        this.maleFilterCoverShow = false;
        this.maleSpotLoading = false;
        this.maleTotal = res.data.total;
      });
    },
    // 选中现货
    switchChange(item, value, text) {
      if (value === true && item.bargainPrice === '是') {
        this.$message({
          message: 'TJ款属于特价款，不参与调换货',
          type: 'warning',
          duration: 1500,
        });
      }
      this.$emit('selectionSpot', value, text);
    },
    // 情侣款现货排序
    loverSpotOrder(value) {
      switch (value) {
        case '主石重量由低到高':
          this.sortCode = '1';
          this.isAsc = true;
          break;
        case '主石重量由高到低':
          this.sortCode = '1';
          this.isAsc = false;
          break;
        case '标签价格由低到高':
          this.sortCode = '2';
          this.isAsc = true;
          break;
        case '标签价格由高到低':
          this.sortCode = '2';
          this.isAsc = false;
          break;
      }
      // first为男款
      if (this.spotActive == 'first') {
        this.maleSpotList = [];
        this.malePageNum = 1;
        this.getMaleDiamondSpotList();
      } else {
        this.femaleSpotList = [];
        this.femalePageNum = 1;
        this.getFemaleDiamondSpotList();
      }
    },
    femaleLoad() {
      if (this.femaleFilterCoverShow == false && this.femaleMore == false) {
        this.femalePageNum++;
        this.femaleSpotLoading = true;
        this.getFemaleDiamondSpotList();
      }
    },
    maleLoad() {
      if (this.maleFilterCoverShow == false && this.maleMore == false) {
        this.malePageNum++;
        this.maleSpotLoading = true;
        this.getMaleDiamondSpotList();
      }
    },
    // 加入购物车重置现货列表
    resetSpotList() {
      this.maleSpotList = [];
      this.femaleSpotList = [];
      this.malePageNum = 1;
      this.femalePageNum = 1;
      this.getMaleDiamondSpotList();
      this.getFemaleDiamondSpotList();
    },
  },
};
</script>
<style lang="scss" scoped>
.loverSortBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;

  .spotTabs {
    width: 230px;
  }

  .spotTabs .el-tabs__nav {
    width: 100%;
  }

  .spotTabs .el-tabs__item {
    width: 50%;
    text-align: center;
  }

  .spotTabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom: #e4e7ed;
    background: #ec9368;
    color: #fff;
  }

  .spotTabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:hover {
    color: #fff !important;
  }

  .spotTabs .el-tabs__item:hover {
    color: #ec9368 !important;
  }

  .el-select-dropdown__item {
    margin: 10px 0 0 10px;
    display: inline-block;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border: 1px solid #d3d3d3;
    padding: 0;
    font-size: 16px;
    color: #000;
  }

  .el-select-dropdown__item.selected {
    background: #fff2ea;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    border: 1px solid #ee9c75;
  }
}
</style>
