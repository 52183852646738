<template>
  <div class="custom">
    <div class="fx mainStoneSize">
      <div class="title">
        {{ mosaic ? '主石重大小：' : '金重大小：' }}
      </div>
      <div class="mainStoneClssBoxFx">
        <div :class="[mosaic ? 'mainStoneClssBox' : 'getwegClssBox']">
          <el-select
            v-model="dataFrom.mainStone"
            @change="getPriceRange('主石')"
            :popper-append-to-body="false"
            clearable
            :placeholder="mosaic ? '请选择主石重大小' : '请选择金重大小'"
          >
            <div class="mainStone">
              <el-option v-for="(item, index) in dropDownOptional.mainStoneList" :key="index" :label="item" :value="item"></el-option>
            </div>
          </el-select>
        </div>
        <div v-if="mosaic">
          <el-input
            @input="weihechangge($event)"
            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,5})?).*$/g, '$1')"
            v-model="dataFrom.mainStoneSize"
            @blur="onBlur"
            placeholder="请输入主石重量"
            class="mainStoneClss"
          ></el-input>
        </div>
        <div class="unit" v-if="mosaic">
          ct
        </div>
      </div>
    </div>
    <div class="fx">
      <div class="title">可选材质：</div>
      <div class="selectBox material">
        <el-radio-group v-model="dataFrom.texture" size="small" @change="getPriceRange">
          <el-radio-button v-for="item in dropDownOptional.textureList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
        </el-radio-group>
        <!-- <el-select v-model="dataFrom.texture" @change="getPriceRange" :popper-append-to-body="false" clearable placeholder>
          <div class="texture">
            <el-option v-for="(item, index) in dropDownOptional.textureList" :key="index" :label="item" :value="item"></el-option>
          </div>
        </el-select> -->
      </div>
    </div>
    <div class="fx">
      <div class="title">净<span class="hidden">间隔</span>度：</div>
      <div class="selectBox material">
        <el-radio-group v-model="dataFrom.mainStoneClarity" size="small" @change="getPriceRange">
          <el-radio-button v-for="item in dropDownOptional.mainStoneClarity" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
        </el-radio-group>
        <!-- <el-select v-model="dataFrom.mainStoneClarity" @change="getPriceRange" :popper-append-to-body="false" placeholder clearable>
          <div>
            <el-option v-for="(item, index) in dropDownOptional.mainStoneClarity" :key="index" :label="item" :value="item"></el-option>
          </div>
        </el-select> -->
      </div>
    </div>
    <div class="fx">
      <div class="title">色<span class="hidden">间隔</span>度：</div>
      <div class="selectBox material">
        <el-radio-group v-model="dataFrom.mainStoneColour" size="small" @change="getPriceRange">
          <el-radio-button v-for="item in dropDownOptional.mainStoneColour" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
        </el-radio-group>
        <!-- <el-select v-model="dataFrom.mainStoneColour" @change="getPriceRange" :popper-append-to-body="false" placeholder clearable>
          <div>
            <el-option v-for="(item, index) in dropDownOptional.mainStoneColour" :key="index" :label="item.label" :value="item"></el-option>
          </div>
        </el-select> -->
      </div>
    </div>
    <div class="fx" v-if="dropDownOptional.gemNameList && dropDownOptional.gemNameList.length > 0">
      <div class="title">
        辅石名称：
      </div>
      <div class="selectBox material">
        <el-radio-group v-model="dataFrom.gemName" size="small" @change="getPriceRange">
          <el-radio-button v-for="item in dropDownOptional.gemNameList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
        </el-radio-group>
        <!-- <el-select v-model="dataFrom.gemName" @change="getPriceRange" :popper-append-to-body="false" placeholder clearable>
          <div>
            <el-option v-for="(item, index) in dropDownOptional.gemNameList" :key="index" :label="item.label" :value="item"></el-option>
          </div>
        </el-select> -->
      </div>
    </div>
    <div class="fx" v-if="dropDownOptional.formatTypes && dropDownOptional.formatTypes.length > 0">
      <div class="title">版<span class="hidden">间隔</span>型：</div>
      <div class="selectBox material">
        <el-radio-group v-model="dataFrom.formatType" size="small" @change="getPriceRange">
          <el-radio-button v-for="item in dropDownOptional.formatTypes" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
        </el-radio-group>
        <!-- <el-select v-model="dataFrom.formatType" @change="getPriceRange" :popper-append-to-body="false" placeholder clearable>
          <div>
            <el-option v-for="(item, index) in dropDownOptional.formatTypes" :key="index" :label="item.label" :value="item"></el-option>
          </div>
        </el-select> -->
      </div>
    </div>
    <div class="fx" v-if="productCategoryList.includes(DataList.productCategoryName)">
      <div class="title">手<span class="hidden">间隔</span>寸：</div>
      <div class="selectBox material">
        <el-radio-group v-model="dataFrom.ringSize" size="small" @change="getPriceRange">
          <el-radio-button v-for="item in dropDownOptional.handSizeList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
        </el-radio-group>
        <!-- <el-select v-model="dataFrom.ringSize" @change="getPriceRange" :popper-append-to-body="false" placeholder clearable>
          <div>
            <el-option v-for="(item, index) in dropDownOptional.handSizeList" :key="index" :label="item" :value="item"></el-option>
          </div>
        </el-select> -->
      </div>
    </div>
    <div class="fx marginFx">
      <div class="title">
        是否改款：
      </div>
      <div class="mainStoneClssBoxFx selectBox">
        <el-radio-group v-model="dataFrom.shapeOptimize">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="fx marginFx">
      <div class="title">
        戒壁封石：
      </div>
      <div class="mainStoneClssBoxFx selectBox">
        <el-radio-group v-model="dataFrom.seal">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <!-- 证书类型 -->
    <div class="fx marginFx">
      <div class="giaFl"><span>G</span><span>I</span><span>A：</span></div>
      <div class="mainStoneClssBoxFx selectBox">
        <el-radio-group v-model="dataFrom.gia" @change="certificateTypeMethod">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="fx" v-if="dataFrom.gia === 'Y'">
      <div class="title">切<span class="hidden">间隔</span>工：</div>
      <div class="material fluorescenceCl">
        <el-radio-group v-model="dataFrom.cut" size="small">
          <el-radio-button label="VG（很好）"></el-radio-button>
          <el-radio-button label="EX（极好）"></el-radio-button>
          <el-radio-button label="G（好）"></el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="fx" v-if="dataFrom.gia === 'Y'">
      <div class="title">荧<span class="hidden">间隔</span>光：</div>
      <div class="material fluorescenceCl">
        <el-radio-group v-model="dataFrom.fluorescence" size="small">
          <el-radio-button label="N（无）"></el-radio-button>
          <el-radio-button label="F（弱）"></el-radio-button>
          <el-radio-button label="M（中）"></el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <!-- 下单件数 -->
    <div class="box_detail_input marginFx">
      <span class="title placeNum">下单件数：</span>
      <el-input-number
        v-model="dataFrom.quantity"
        :min="0"
        :max="9999"
        @change="realTimePriceOrderNumber"
        @input.native="updataQuantity"
        label="描述文字"
      ></el-input-number>
      <span class="prompt">(起订量{{ leastQuantity }}件，可定单不同规格)</span>
    </div>
    <!-- 价格范围 -->
    <div v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')" class="marginFx">
      <span class="title">批发价格：</span>
      <span class="value-font">{{ dataFrom.tradePrice }}</span>
    </div>
    <!-- 价格范围 -->
    <div class="marginFx">
      <span class="title">标签价格：</span>
      <span class="value-font">{{ dataFrom.labelPrice }}</span>
    </div>
  </div>
</template>
<script>
import { PRODUCT_CATEGOYR_NAME } from '@/utils/const';
import { getOptional } from '@/api/detailsApi';
export default {
  name: 'Custom',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    mosaic: {
      type: Boolean,
      default() {
        return false;
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        quantity: 0, // 数量
        gia: 'N', // GIA
        fluorescence: 'M（中）', // 荧光
        cut: 'G（好）', // 切工
        shapeOptimize: 'N', // 是否改款
        seal: 'N', // 戒壁封石
        goldWeigh: '',
        isRevision: '',
        labelPrice: 0,
        tradePrice: 0,
      },
      dropDownOptional: {},
      productCategoryList: PRODUCT_CATEGOYR_NAME,
    };
  },
  mounted() {
    // 获取可选属性
    this.getOptional();
    // 获取金重
    this.getPriceRange();
  },
  methods: {
    //改变gia
    certificateTypeMethod() {
      this.dataFrom.fluorescence = 'M（中）';
      this.dataFrom.cut = 'G（好）';
    },
    // 获取可选属性
    getOptional(text) {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        mainStone: this.DataList.mainStone,
        columnCode: this.$route.query.codeType,
        gender: '',
      };
      getOptional(obj).then((res) => {
        this.dropDownOptional = { ...res.data };
        this.dataFrom.isRevision = this.dropDownOptional.isRevision;
        // 规格重新排序
        this.dropDownOptional.handSizeList?.sort(function(a, b) {
          return a - b;
        });
        if (text === '主石') {
          if (this.dropDownOptional.textureList.length > 0) {
            this.dataFrom.texture = this.dropDownOptional.textureList[0];
          }
          if (this.dropDownOptional.mainStoneClarity.length > 0) {
            this.dataFrom.mainStoneClarity = this.dropDownOptional.mainStoneClarity[0];
          }
          if (this.dropDownOptional.mainStoneColour.length > 0) {
            this.dataFrom.mainStoneColour = this.dropDownOptional.mainStoneColour[0];
          }
          if (this.dropDownOptional.gemNameList.length > 0) {
            this.dataFrom.gemName = this.dropDownOptional.gemNameList[0];
          }
          if (this.dropDownOptional.formatTypes.length > 0) {
            this.dataFrom.formatType = this.dropDownOptional.formatTypes[0];
          }
          if (this.dropDownOptional.handSizeList.length > 0) {
            this.dataFrom.ringSize = this.dropDownOptional.handSizeList[0];
          }
        }
      });
    },
    //获取价格范围
    getPriceRange(text) {
      if (text === '主石' && this.mosaic) {
        this.dataFrom.mainStoneSize = this.dataFrom.mainStone?.split('-')[0];
      }
      if (text === '主石') {
        this.dataFrom.texture = null;
        this.dataFrom.mainStoneClarity = null;
        this.dataFrom.mainStoneColour = null;
        this.dataFrom.gemName = null;
        this.dataFrom.formatType = null;
        this.dataFrom.ringSize = null;
        this.getOptional(text);
      }
      this.$forceUpdate();
      // getPriceRange(this.dataFrom).then(res => {
      //   this.dataFrom = { ...this.dataFrom, ...res.data }
      // })
    },
    // 数量改变实时触发
    updataQuantity(event) {
      this.realTimePriceOrderNumber(Number(event.target.value));
    },
    // 数量改变计算价格
    realTimePriceOrderNumber(value) {
      // this.getPriceRange();
      this.$emit('selectionCustom', value);
    },
    // 解决input赋值之后无法输入bug
    weihechangge(e) {
      this.$forceUpdate();
    },
    //输入主石重量判断
    onBlur() {
      if (this.dataFrom.mainStone) {
        var arr = this.dataFrom.mainStone.split('-');
        if (
          (this.dataFrom.mainStoneSize && Number(this.dataFrom.mainStoneSize) < Number(arr[0])) ||
          Number(this.dataFrom.mainStoneSize) > Number(arr[1])
        ) {
          this.$message({
            message: '超过主石范围',
            type: 'warning',
            duration: 1500,
          });
          this.dataFrom.mainStoneSize = this.dataFrom.mainStone?.split('-')[0];
        } else if (!this.dataFrom.mainStoneSize) {
          this.dataFrom.mainStoneSize = this.dataFrom.mainStone?.split('-')[0];
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom {
  .fx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 8px 0;
  }
  .mainStoneClssBoxFx {
    width: 675px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .mainStoneClssBox {
    margin-right: 14px;

    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .getwegClssBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .material {
    width: 800px;
    ::v-deep .el-radio-button__inner {
      min-width: 65px;
    }
  }
  .fluorescenceCl {
    ::v-deep.el-radio-button__inner {
      width: 100px;
    }
  }

  .el-select-dropdown__item {
    margin: 10px 0 0 10px;
    display: inline-block;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border: 1px solid #d3d3d3;
    padding: 0;
    font-size: 16px;
    color: #000;
  }

  .el-select-dropdown__item.selected {
    background: #fff2ea;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    border: 1px solid #ee9c75;
  }

  .unit {
    width: 46px;
    height: 46px;
    text-align: center;
    line-height: 46px;
    background: #f3f3f3;
    font-size: 18px;
    color: #999;
  }

  .title {
    font-size: 18px;
    color: #000;

    .hidden {
      visibility: hidden;
    }
  }

  .placeNum {
    margin-right: 35px;
  }

  .giaFl {
    font-size: 18px;
    color: #000;
    display: flex;
    width: 90px;
    justify-content: space-between;
    align-items: baseline;
  }

  .separate {
    width: 100%;
    height: 1px;
    background: #dcdfe6;
    margin: 5px 0;
  }

  .weightGold {
    color: #ec9368;
    margin-right: 39px;
    font-size: 18px;
  }

  .value-font {
    font-size: 18px;
    color: #ec9368;
    margin-left: 35px;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #ec9368;
    border-color: #ec9368;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__inner:hover {
    color: rgb(236, 147, 104);
  }
  ::v-deep .el-radio-button__inner {
    margin: 5px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px;
  }
}
</style>
