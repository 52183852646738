<template>
  <div class="order diamonDetails" v-cloak>
    <appheader></appheader>
    <div class="box">
      <div>
        <div @mouseenter="stopImglunbo" @mouseleave="mouseLeave" class="magnifier-wrap">
          <svg-icon v-if="DataList.isPresell === '1'" iconClass="pre-sale-label" className="pre-sale-label"></svg-icon>
          <magnifier :src="imgurl"></magnifier>
        </div>
        <div class="banner">
          <div class="slide">
            <div :class="[index == 0 ? 'imgbck' : '', 'imgbox']" v-for="(item, index) in DataList.imgPathList" :key="index" @click="changeImg(index)">
              <img :src="item" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="introduction">
          <div class="productNameClass">{{ DataList.productName }}</div>
          <div>
            <div v-if="!isColleFlag" @click="colleBtn('收藏')" class="introduction cursor">
              <svg-icon iconClass="collection_default" className="collection_default"></svg-icon>收藏商品
            </div>
            <div v-if="isColleFlag" @click="colleBtn('取消')" class="introduction  cursor">
              <svg-icon iconClass="collection_selected" className="collection_default"></svg-icon>已收藏
            </div>
          </div>
        </div>
        <CopyStyleNumber :styleNumber="DataList.styleNumber" :isPresell="DataList.isPresell"></CopyStyleNumber>
        <!-- 单独款 -->
        <div class="box_goumai" v-if="DataList.isCouples === '2'">
          <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
            <el-tab-pane :disabled="DataList.isPresell === '1'" label="现货" name="first">
              <Spot @toCustom="toCustom" ref="spot" @selectionSpot="selectionSpot" :DataList="DataList" />
            </el-tab-pane>
            <el-tab-pane v-if="hasMainBodyType()[0].value !== '2'" :label="DataList.isPresell === '1' ? '预售定单' : '定单'" name="second">
              <Custom :mosaic="mosaic" :leastQuantity="DataList.leastQuantity" ref="custom" @selectionCustom="selectionCustom" :DataList="DataList" />
            </el-tab-pane>
          </el-tabs>
          <!-- 备注 -->
          <div
            class="textareaRemark"
            v-if="(DataList.isCouples === '2' && activeName === 'first' && $refs.spot && $refs.spot.spotList.length > 10) || activeName === 'second'"
          >
            <el-input
              :rows="6"
              :maxlength="64"
              type="textarea"
              placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
              v-model="productRemark"
            ></el-input>
          </div>
        </div>
        <!-- 情侣对戒 -->
        <div v-if="DataList.isCouples === '1'">
          <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
            <el-tab-pane :disabled="DataList.isPresell === '1'" label="现货" name="first">
              <LoversSpot @toCustom="toCustom" ref="loversSpot" @selectionSpot="selectionSpot" :DataList="DataList" />
            </el-tab-pane>
            <el-tab-pane v-if="hasMainBodyType()[0].value !== '2'" :label="DataList.isPresell === '1' ? '预售定单' : '定单'" name="second">
              <LoversCustom
                :mosaic="mosaic"
                :leastQuantity="DataList.leastQuantity"
                @selectionCustom="selectionCustom"
                ref="loversCustom"
                :DataList="DataList"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div
          class="subscribeToContainer"
          v-if="
            (activeName === 'first' && $refs.spot && $refs.spot.total <= 10) ||
              (activeName === 'first' && $refs.loversSpot && ($refs.loversSpot.femaleTotal <= 10 || $refs.loversSpot.maleTotal <= 10))
          "
        >
          <div class="subscribeTo" @click="updateSubscribe" v-if="!isSubscribe">
            <img src="../../Img/detaile/not-subscribe-to.png" alt="" /> 订阅到货通知
          </div>
          <div class="subscribeTo hasSubscribe" @click="updateSubscribe" v-if="isSubscribe">
            <img src="../../Img/detaile/has-subscribe-to.png" alt="" /> 已订阅到货通知
          </div>
        </div>
        <div
          class="addClass"
          v-if="
            (DataList.isCouples === '2' && activeName === 'first' && $refs.spot && $refs.spot.spotList.length > 0) ||
              activeName === 'second' ||
              (DataList.isCouples === '1' &&
                activeName === 'first' &&
                $refs.loversSpot &&
                $refs.loversSpot.femaleTotal > 10 &&
                $refs.loversSpot.maleTotal > 10)
          "
        >
          <div v-if="DataList.isCouples === '2'">
            (已选现货
            <span class="addCartNumber">{{ numberTotal.spotNumber }}</span>
            件<span v-if="hasMainBodyType()[0].value !== '2'"
              >，定单<span class="addCartNumber">{{ numberTotal.customNumber }}</span
              ><span>件</span></span
            >)
          </div>
          <div v-if="DataList.isCouples === '1'">
            <div class="addLoversFemale">
              女款：(已选现货
              <span class="addCartNumber">{{ numberTotal.femaleSpotNumber }}</span>
              件<span v-if="hasMainBodyType()[0].value !== '2'"
                >，定单<span class="addCartNumber">{{ numberTotal.femaleCustomNumber }}</span
                >件</span
              >)
            </div>
            <div>
              男款：(已选现货
              <span class="addCartNumber">{{ numberTotal.maleSpotNumber }}</span>
              件<span v-if="hasMainBodyType()[0].value !== '2'"
                >，定单<span class="addCartNumber">{{ numberTotal.maleCustomNumber }}</span> 件</span
              >)
            </div>
          </div>
          <BeInterestedIn
            @interestedInUpdata="interestedInUpdata"
            :id="$route.query.id"
            :isLikeTwo="DataList.isLikeTwo"
            :isPresell="DataList.isPresell"
            :styleNumber="DataList.styleNumber"
          ></BeInterestedIn>
          <div class="onebottom">
            <el-button @click="pushshopping()">加入购物车</el-button>
          </div>
        </div>
      </div>
      <div class="toBottom topBottom" v-if="!goTopShow && (recommendationList.length > 0 || mantleSystemList.length > 0)" @click="bottomScrollClick">
        <svg-icon iconClass="gengduotuijian" className="gengduotuijian"></svg-icon>
        更多推荐
      </div>
    </div>
    <!-- 套系推荐 -->
    <MantleSystemRecommended :mantleSystemList="mantleSystemList" :codeType="$route.query.codeType"> </MantleSystemRecommended>
    <!-- 相似推荐 -->
    <SimilarRecommendation :recommendationList="recommendationList" :codeType="$route.query.codeType"> </SimilarRecommendation>
    <bottom></bottom>
    <div class="toTop topBottom" @click="topScrollClick" v-if="goTopShow">
      <svg-icon iconClass="fanhuidingbu" className="gengduotuijian"></svg-icon>
      返回顶部
    </div>
  </div>
</template>

<script>
import Magnifier from '../moudel/magnifier';
import $ from 'jquery';
import Appheader from '../moudel/Appheader';
import bottom from '../bady_bottom/bottom';
import { debounce } from '@/utils/index';
import { getDiamondInfo, addCollection, getSimilarity, getMantleSystem, whetherSubscribeTo, editSubscribeTo, messagePoint } from '@/api/detailsApi';
import { modifyCartPublic } from '@/api/cartApi';
import Spot from './components/Spot.vue';
import Custom from './components/Custom.vue';
import LoversSpot from './components/LoversSpot.vue';
import LoversCustom from './components/LoversCustom.vue';
import BeInterestedIn from '../../components/BeInterestedIn.vue';
import SimilarRecommendation from '../../components/SimilarRecommendation.vue';
import MantleSystemRecommended from '../../components/MantleSystemRecommended.vue';
import CopyStyleNumber from '../../components/CopyStyleNumber.vue';
import ls from '@/utils/localStorage';
export default {
  components: {
    Magnifier,
    Appheader,
    bottom,
    Spot,
    Custom,
    LoversSpot,
    LoversCustom,
    BeInterestedIn,
    SimilarRecommendation,
    MantleSystemRecommended,
    CopyStyleNumber,
  },
  data() {
    return {
      activeName: 'first',
      total: 0,
      nantotal: 0,
      DataList: {
        styleNumber: this.$route.query.styleNumber,
      },
      productRemark: '',
      imgurl: '',
      index: 0, //开始轮播的值
      timer: null, //停止轮播的值
      size: 0, //图片的张数
      picHeight: 0, //图片的高度
      numberTotal: {
        spotNumber: 0, //普通款隐藏数组长度
        femaleSpotNumber: 0, //女款隐藏数组长度
        maleSpotNumber: 0, //男款隐藏数组长度
        customNumber: 0, //单款已选现货件数
        femaleCustomNumber: 0, //女款已选现货件数
        maleCustomNumber: 0, //男款已选现货件数
      },
      isColleFlag: false,
      mosaic: false,
      recommendationList: [], //相似推荐
      mantleSystemList: [], //套系推荐
      scrollTop: null,
      goTopShow: false,
      isSubscribe: false,
      flag: false,
    };
  },
  watch: {
    $route: {
      handler() {
        // 获取商品详情
        this.getDiamondInfo();
      },
      deep: true,
    },
  },
  created() {
    //现货是first 定单second
    if (
      this.$route.query.codeType == 'diamond_hot_sales' ||
      this.$route.query.codeType == 'diamond_new' ||
      this.$route.query.codeType == 'diamond_group_main' ||
      this.$route.query.codeType == 'diamond' ||
      this.$route.query.codeType == 'custom_gold_mosaic' ||
      this.$route.query.codeType == 'custom_gold'
    ) {
      this.activeName = 'second';
    } else if (
      this.$route.query.codeType == 'current_diamond' ||
      this.$route.query.codeType == 'current_gold' ||
      this.$route.query.codeType == 'star_style' ||
      this.$route.query.codeType == 'current_gold_mosaic' ||
      this.$route.query.codeType == 'current_watch'
    ) {
      this.activeName = 'first';
    }
    if (this.$route.query.codeType == 'custom_diamond') {
      this.activeName = 'second';
    } else if (this.$route.query.codeType == 'current_product') {
      this.activeName = 'first';
    }
    if (this.$route.query.styleFlag == '钻石现货') {
      this.activeName = 'first';
    } else if (this.$route.query.styleFlag == '钻石定单') {
      this.activeName = 'second';
    }
    // 监听滚动事件
    window.addEventListener('scroll', this.handleScroll);
    // 获取商品详情
    this.getDiamondInfo();
    if (ls.get('coludQuery')) {
      // 埋点
      this.messagePoint('0');
    }
  },
  destroyed() {
    ls.remove('coludQuery');
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    messagePoint(pointType) {
      const query = {
        styleNumber: this.$route.query.styleNumber,
        pointType: pointType,
      };
      messagePoint(query).then((res) => {});
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 300) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },
    bottomScrollClick() {
      let timer = null;
      const _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop != 1000) {
          _that.scrollTop = 1000;
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = true;
        }
      });
    },
    topScrollClick() {
      let timer = null;
      const _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop = 0;
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      });
    },
    // 点击感兴趣刷新页面
    interestedInUpdata() {
      this.getDiamondInfo();
    },
    // 相似推荐
    getRecommendation(styleNumber) {
      //相似推荐
      getSimilarity(styleNumber).then((res) => {
        this.recommendationList = res.data.records || [];
      });
      //套系推荐
      getMantleSystem(styleNumber).then((res) => {
        this.mantleSystemList = res.data.records || [];
      });
    },
    toCustom() {
      if (this.hasMainBodyType()[0].value !== '2') {
        this.activeName = 'second';
      } else {
        this.activeName = 'first';
      }
    },
    // 选中现货
    selectionSpot(value, text) {
      if (text === '男') {
        if (value === true) {
          this.numberTotal.maleSpotNumber++;
        } else {
          this.numberTotal.maleSpotNumber--;
        }
      } else if (text === '女') {
        if (value === true) {
          this.numberTotal.femaleSpotNumber++;
        } else {
          this.numberTotal.femaleSpotNumber--;
        }
      } else {
        if (value === true) {
          this.numberTotal.spotNumber++;
        } else {
          this.numberTotal.spotNumber--;
        }
      }
    },
    //选中定单
    selectionCustom(value, text) {
      if (text === '女') {
        this.numberTotal.femaleCustomNumber = value;
      } else if (text === '男') {
        this.numberTotal.maleCustomNumber = value;
      } else {
        this.numberTotal.customNumber = value;
      }
    },
    // 点击收藏
    colleBtn(text) {
      let obj = {
        id: this.$route.query.styleNumber,
        isLike: text === '收藏' ? 1 : 0,
      };
      addCollection(obj).then((res) => {
        text === '收藏' ? (this.isColleFlag = true) : (this.isColleFlag = false);
        this.$message({
          message: res.data,
          type: 'success',
          duration: 1500,
        });
      });
    },
    // 鼠标移开开始轮播
    mouseLeave() {
      this.autoSlide(); // 图片开始再次轮播
    },
    // 点击清除定时器从新轮播
    stopImglunbo() {
      clearInterval(this.timer);
    },
    // 点击定制现货清空所有已选勾选状态数量
    handleClick(tab, event) {
      this.$refs.spot?.spotList.forEach((ele) => {
        ele.switchFlag = false;
      });
      this.$refs.loversSpot?.maleSpotList.forEach((ele) => {
        ele.switchFlag = false;
      });
      this.$refs.loversSpot?.femaleSpotList.forEach((ele) => {
        ele.switchFlag = false;
      });
      if (this.$refs.custom) {
        this.$refs.custom.dataFrom.quantity = 0;
        this.$refs.custom.dataFrom.tradePrice = 0;
        this.$refs.custom.dataFrom.labelPrice = 0;
      }
      if (this.$refs.loversCustom) {
        this.$refs.loversCustom.femaleDataFrom.quantity = 0;
        this.$refs.loversCustom.maleDataFrom.quantity = 0;
        this.$refs.loversCustom.femaleDataFrom.tradePrice = 0;
        this.$refs.loversCustom.femaleDataFrom.labelPrice = 0;
        this.$refs.loversCustom.maleDataFrom.tradePrice = 0;
        this.$refs.loversCustom.maleDataFrom.labelPrice = 0;
      }
      for (let key in this.numberTotal) {
        if (this.numberTotal.hasOwnProperty(key)) {
          this.numberTotal[key] = 0;
        }
      }
    },
    // 加入购物车
    pushshopping: debounce(
      function() {
        let list = [];
        for (let key in this.numberTotal) {
          if (this.numberTotal.hasOwnProperty(key)) {
            list.push(this.numberTotal[key]);
          }
        }
        if (!list.some((a) => a > 0)) {
          this.$message({
            message: '下单数量不能为0',
            type: 'warning',
            duration: 1000,
          });
        } else {
          let obj = {
            productDtoList: [],
            shopType: 4,
            type: 1,
            isCouples: this.DataList.isCouples,
            orderPath: 1,
            requestFrom: 1,
            spotFlag: 1,
            purchaseSource: ls.get('coludQuery') ? 0 : 1, //0 云平台产品推荐, 1 其他渠道"
          };
          if (this.DataList.isCouples === '1') {
            if (this.activeName === 'first') {
              this.$refs.loversSpot.maleSpotList?.forEach((ele) => {
                if (ele.switchFlag === true) {
                  let barcodeObj = {
                    barCode: ele.barcode,
                    styleNumber: this.DataList.styleNumber,
                  };
                  obj.productDtoList.push(barcodeObj);
                }
              });
              this.$refs.loversSpot.femaleSpotList?.forEach((ele) => {
                if (ele.switchFlag === true) {
                  let barcodeObj = {
                    barCode: ele.barcode,
                    styleNumber: this.DataList.styleNumber,
                  };
                  obj.productDtoList.push(barcodeObj);
                }
              });
            } else {
              obj.type = 2;
              obj.spotFlag = 2;
              if (this.numberTotal.femaleCustomNumber > 0 && this.numberTotal.maleCustomNumber > 0) {
                obj.productDtoList.push(this.$refs.loversCustom.femaleDataFrom);
                obj.productDtoList.push(this.$refs.loversCustom.maleDataFrom);
              } else if (this.numberTotal.femaleCustomNumber > 0) {
                obj.productDtoList.push(this.$refs.loversCustom.femaleDataFrom);
              } else {
                obj.productDtoList.push(this.$refs.loversCustom.maleDataFrom);
              }
            }
          } else {
            // first现货 second定单
            if (this.activeName === 'first') {
              this.$refs.spot.spotList?.forEach((ele) => {
                if (ele.switchFlag === true) {
                  let barcodeObj = {
                    barCode: ele.barcode,
                    styleNumber: this.DataList.styleNumber,
                    productRemark: this.productRemark,
                  };
                  obj.productDtoList.push(barcodeObj);
                }
              });
            } else {
              obj.type = 2;
              obj.spotFlag = 2;
              this.$refs.custom.dataFrom.productRemark = this.productRemark;
              obj.productDtoList.push(this.$refs.custom.dataFrom);
            }
          }
          modifyCartPublic(obj, 'post').then((res) => {
            if (res.code == 0) {
              this.$refs.spot?.spotList.forEach((ele) => {
                ele.switchFlag = false;
              });
              this.$refs.loversSpot?.maleSpotList.forEach((ele) => {
                ele.switchFlag = false;
              });
              this.$refs.loversSpot?.femaleSpotList.forEach((ele) => {
                ele.switchFlag = false;
              });
              this.$confirm('添加成功', '提示', {
                confirmButtonText: '去购物车',
                cancelButtonText: '继续添加',
                type: 'success',
              })
                .then(() => {
                  this.$router.push('/shoppingCart');
                })
                .catch(() => {
                  // 加完购物车刷新现货列表
                  this.$refs.spot?.resetSpotList();
                  this.$refs.loversSpot?.resetSpotList();
                });
            }
          });
        }
      },
      500,
      true
    ),
    // 订阅-取消订阅
    updateSubscribe() {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        columnCode: this.$route.query.codeType,
        requestFrom: 2,
      };
      if (!this.flag) {
        this.flag = true;
        editSubscribeTo(obj).then((res) => {
          this.isSubscribe = !this.isSubscribe;
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000,
          });
          this.flag = false;
        });
      }
    },
    // 查询是否订阅
    whetherSubscribeTo(styleNumber) {
      let obj = {
        styleNumber: styleNumber,
        columnCode: this.$route.query.codeType,
      };
      whetherSubscribeTo(obj).then((res) => {
        this.isSubscribe = res.data;
      });
    },
    //获取商品详情
    getDiamondInfo() {
      getDiamondInfo(this.$route.query.styleNumber).then((res) => {
        this.DataList = res.data;
        //isCouples 1是情侣款 2 否
        //isPresell 1是预收款 2 否
        //isLike 0是未收藏  2是已收藏
        //isLikeTwo 0是未感兴趣  1是感兴趣
        if (this.DataList.isLike === '0') {
          this.isColleFlag = false;
        } else {
          this.isColleFlag = true;
        }
        this.imgurl = res.data.imgPathList[0];
        if (res.data.imgPathList.length > 3) {
          this.DataList.imgPathList = res.data.imgPathList.splice(0, 3);
        } else {
          this.DataList.imgPathList = res.data.imgPathList;
        }
        if (this.DataList.productName.indexOf('6G镶嵌') != -1) {
          this.mosaic = true;
        }
        if (this.DataList.isPresell === '1') {
          this.activeName = 'second';
        }
        if (this.hasMainBodyType()[0].value === '2') {
          this.activeName = 'first';
        }
        // 相识推荐-套系
        this.getRecommendation(this.DataList.styleNumber);
        // 是否订阅
        this.whetherSubscribeTo(this.DataList.styleNumber);
        // DOM 渲染完毕后执行轮播事件
        this.$nextTick(function() {
          this.picHeight = $('.slide img').width(); //获取宽度度
          this.size = $('.slide').children().length; // 获取图片张数
          this.autoSlide(); //进页面默认轮播
          $('.banner').mouseover(() => {
            clearInterval(this.timer); // 停止图片轮播
          });
          $('.banner').mouseleave(() => {
            this.autoSlide(); // 图片开始再次轮播
          });
          // $('.slide').mouseleave();
        });
      });
    },
    autoSlide() {
      // 图片轮播函数
      this.timer = setInterval(() => {
        let l = this.DataList.imgPathList.length;
        // debugger
        if (this.index < l) {
          $('.imgbox').removeClass('imgbck');
          $('.imgbox')
            .eq(this.index)
            .addClass('imgbck');
          this.imgurl = this.DataList.imgPathList[this.index];
          this.index++;
        } else {
          $('.imgbox').removeClass('imgbck');
          $('.imgbox')
            .eq(0)
            .addClass('imgbck');
          this.imgurl = this.DataList.imgPathList[0];
          this.index = 1;
        }
      }, 3000); //每隔1.5S就切换一次图片
    },
    // 点击图片
    changeImg(index) {
      $('.imgbox').removeClass('imgbck');
      $('.imgbox')
        .eq(index)
        .addClass('imgbck');
      this.imgurl = this.DataList.imgPathList[index];
      this.index = index;
    },
  },
  // 离开页面清除定时器
  beforeDestroy() {
    clearInterval(this.timer);
  },
  beforeRouteLeave(to, from, next) {
    // 从列表页去到别的页面，如果不是详情页，则不缓存列表页
    if (to.name != 'home') {
      this.$route.meta.keepAlive = false;
      this.$route.meta.flag = false;
    } else if (to.name == 'home') {
      this.$route.meta.keepAlive = true;
      this.$route.meta.flag = true;
    }
    next();
  },
};
</script>

<style scoped lang="scss">
.diamonDetails {
  position: relative;
  .content-box {
    width: 800px;
  }

  .introduction {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .productNameClass {
    font-size: 24px;
    color: #ec9368;
  }

  .cursor {
    cursor: pointer;
  }
}
</style>
