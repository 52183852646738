<template>
  <div class="spot">
    <div class="sortBox">
      <div class="sortingFilter">
        <el-select v-model="sorting" :popper-append-to-body="false" @change="spotOrder" placeholder="排序">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div>库存&nbsp;{{ total > 10 ? total : 0 }}&nbsp;件</div>
    </div>
    <div class="singleSpot" v-if="total > 10">
      <div class="infinite-list-wrapper" style="overflow:auto;font-size:14px;max-height:700px;">
        <ul class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled" infinite-scroll-distance="1">
          <li v-for="(item, index) in spotList" class="list-item" :key="index">
            <div class="listChild">
              <div class="specifications">
                <div>
                  材质：<span>{{ item.texture }}</span>
                </div>
                <div>
                  净度：<span>{{ item.mainStoneClarity }}</span>
                </div>
                <div>
                  色度：<span>{{ item.mainStoneColor }}</span>
                </div>
                <div>
                  规格：<span>{{ item.spec }}</span>
                </div>
                <div>
                  主石名称：<span>{{ item.mainStoneName }}</span>
                </div>
                <div>
                  主石数量：<span>{{ item.mainStoneNumber }}</span>
                </div>
                <div>
                  主石重量：<span>{{ item.mainStoneWeight }}</span>
                </div>
              </div>
              <div class="specifications">
                <div>
                  总重量：<span>{{ item.totalWeight }}</span>
                </div>
                <div>
                  辅石1名称：<span>{{ item.assistStoneNameOne }}</span>
                </div>
                <div>
                  辅石1数量：<span>{{ item.assistStoneNumberOne }}</span>
                </div>
                <div>
                  辅石1重量：<span>{{ item.assistStoneWeightOne }}</span>
                </div>
                <div>
                  辅石2名称：<span>{{ item.assistStoneNameTwo }}</span>
                </div>
                <div>
                  辅石2数量：<span>{{ item.assistStoneNumberTwo }}</span>
                </div>
                <div>
                  辅石2重量：<span>{{ item.assistStoneWeightTwo }}</span>
                </div>
              </div>
              <div class="specifications">
                <div>
                  GIA：<span>{{ item.gia | cutFilter }}</span>
                </div>
                <div>
                  系列：<span>{{ item.seriesName }}</span>
                </div>
                <div v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">
                  批发价格：<span class="colorClass">￥{{ item.tradePrice }}</span>
                </div>
                <div>
                  标签价格：<span class="colorClass">￥{{ item.labelPrice }}</span>
                </div>
                <div>
                  特价款：<span>{{ item.bargainPrice }}</span>
                </div>
                <div v-if="item.formatType">
                  版型：<span>{{ item.formatType }}</span>
                </div>
              </div>
              <div class="wetherOrder">
                <el-checkbox
                  :disabled="item.isCart !== 0"
                  v-model="item.switchFlag"
                  @change="switchChange(item, $event)"
                  class="switchClass"
                ></el-checkbox>
              </div>
            </div>
          </li>
        </ul>
        <p v-if="spotLoading" class="lodClass">加载中...</p>
        <p v-if="noMore" class="noMore">没有更多了</p>
      </div>
    </div>
  </div>
</template>
<script>
import { cutFilter } from '@/utils/filetrs';
import { getDiamondSpotList } from '@/api/detailsApi';
export default {
  name: 'Sopt',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      sorting: '主石重量由低到高',
      options: [
        {
          value: '主石重量由低到高',
          label: '主石重量由低到高',
        },
        {
          value: '主石重量由高到低',
          label: '主石重量由高到低',
        },
        {
          value: '标签价格由低到高',
          label: '标签价格由低到高',
        },
        {
          value: '标签价格由高到低',
          label: '标签价格由高到低',
        },
      ],
      spotList: [],
      size: 8,
      current: 1,
      spotLoading: false, //单独款
      filterCoverShow: false,
      total: 0,
      sortCode: '1',
      isAsc: true,
    };
  },
  filters: {
    cutFilter,
  },
  components: {},
  computed: {
    noMore() {
      return this.total <= this.spotList.length;
    },
    disabled() {
      return this.spotLoading || this.noMore;
    },
  },
  mounted() {
    this.getDiamondSpotList();
  },
  methods: {
    // 选中现货
    switchChange(item, value) {
      if (value === true && item.bargainPrice === '是') {
        this.$message({
          message: 'TJ款属于特价款，不参与调换货',
          type: 'warning',
          duration: 1500,
        });
      }
      this.$emit('selectionSpot', value);
    },
    // 单独款现货排序
    spotOrder(value) {
      switch (value) {
        case '主石重量由低到高':
          this.sortCode = '1';
          this.isAsc = true;
          break;
        case '主石重量由高到低':
          this.sortCode = '1';
          this.isAsc = false;
          break;
        case '标签价格由低到高':
          this.sortCode = '2';
          this.isAsc = true;
          break;
        case '标签价格由高到低':
          this.sortCode = '2';
          this.isAsc = false;
          break;
      }
      this.spotList = [];
      this.current = 1;
      this.getDiamondSpotList();
    },
    // 获取现货列表
    getDiamondSpotList() {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        gender: '',
        size: this.size,
        current: this.current,
        sortCode: this.sortCode,
        columnCode: this.$route.query.codeType,
        isAsc: this.isAsc,
      };
      this.filterCoverShow = true;
      getDiamondSpotList(obj).then((res) => {
        res.data.records?.forEach((item) => {
          item.switchFlag = false;
          this.spotList.push(item);
        });
        if (res.data.total === 0) {
          this.$emit('toCustom');
        }
        this.filterCoverShow = false;
        this.spotLoading = false;
        this.total = res.data.total;
      });
    },
    load() {
      if (this.filterCoverShow == false && this.noMore == false) {
        this.current++;
        this.spotLoading = true;
        this.getDiamondSpotList();
      }
    },
    // 加入购物车重置现货列表
    resetSpotList() {
      this.spotList = [];
      this.current = 1;
      this.getDiamondSpotList();
    },
  },
};
</script>
<style scoped lang="scss">
.spot {
  .sortBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .sortingFilter {
      width: 198px;
      display: inline-block;
    }

    .sortingFilter .el-select-dropdown {
      left: 0 !important;
    }

    .sortingFilter .el-scrollbar__view li {
      width: 168px;
    }

    .el-select-dropdown__item {
      margin: 10px 0 0 10px;
      display: inline-block;
      height: 46px;
      text-align: center;
      line-height: 46px;
      border: 1px solid #d3d3d3;
      padding: 0;
      font-size: 16px;
      color: #000;
    }

    .el-select-dropdown__item.selected {
      background: #fff2ea;
      font-size: 16px;
      color: #000;
      font-weight: 400;
      border: 1px solid #ee9c75;
    }
  }
}
</style>
