<template>
  <div class="styleNumberClass">
    <span class="copyStyle">{{ styleNumber }}<span class="copyStyleNumber" @click="seccendCopy">复制款号</span></span>
    <!-- <span class="pre-sale-tips" v-if="isPresell === '1'">预售定单无需支付定金</span> -->
  </div>
</template>

<script>
export default {
  name: 'CopyStyleNumber',
  props: {
    styleNumber: {
      type: String,
      default: '',
    },
    isPresell: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 复制款号
    seccendCopy() {
      var that = this;
      this.$copyText(this.styleNumber).then(
        function(e) {
          that.$message({
            message: '复制成功!',
            type: 'success',
            duration: 1500,
          });
        },
        function(e) {
          that.$message({
            message: '复制失败!',
            type: 'warning',
            duration: 1500,
          });
        }
      );
    },
  },
};
</script>

<style scoped></style>
